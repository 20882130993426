@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-RegularItalic.eot");
  src: local("Reckless Neue Regular Italic"),
    local("RecklessNeue-RegularItalic"),
    url("../fonts/RecklessNeue-RegularItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/RecklessNeue-RegularItalic.woff2") format("woff2"),
    url("../fonts/RecklessNeue-RegularItalic.woff") format("woff"),
    url("../fonts/RecklessNeue-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-ThinItalic.eot");
  src: local("Reckless Neue Thin Italic"), local("RecklessNeue-ThinItalic"),
    url("../fonts/RecklessNeue-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/RecklessNeue-ThinItalic.woff2") format("woff2"),
    url("../fonts/RecklessNeue-ThinItalic.woff") format("woff"),
    url("../fonts/RecklessNeue-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Reckless Neue Book";
  src: url("../fonts/RecklessNeue-Book.eot");
  src: local("Reckless Neue Book"), local("RecklessNeue-Book"),
    url("../fonts/RecklessNeue-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RecklessNeue-Book.woff2") format("woff2"),
    url("../fonts/RecklessNeue-Book.woff") format("woff"),
    url("../fonts/RecklessNeue-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-Regular.eot");
  src: local("Reckless Neue Regular"), local("RecklessNeue-Regular"),
    url("../fonts/RecklessNeue-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RecklessNeue-Regular.woff2") format("woff2"),
    url("../fonts/RecklessNeue-Regular.woff") format("woff"),
    url("../fonts/RecklessNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-Light.eot");
  src: local("Reckless Neue Light"), local("RecklessNeue-Light"),
    url("../fonts/RecklessNeue-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RecklessNeue-Light.woff2") format("woff2"),
    url("../fonts/RecklessNeue-Light.woff") format("woff"),
    url("../fonts/RecklessNeue-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-Thin.eot");
  src: local("Reckless Neue Thin"), local("RecklessNeue-Thin"),
    url("../fonts/RecklessNeue-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RecklessNeue-Thin.woff2") format("woff2"),
    url("../fonts/RecklessNeue-Thin.woff") format("woff"),
    url("../fonts/RecklessNeue-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-Heavy.eot");
  src: local("Reckless Neue Heavy"), local("RecklessNeue-Heavy"),
    url("../fonts/RecklessNeue-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RecklessNeue-Heavy.woff2") format("woff2"),
    url("../fonts/RecklessNeue-Heavy.woff") format("woff"),
    url("../fonts/RecklessNeue-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-LightItalic.eot");
  src: local("Reckless Neue Light Italic"), local("RecklessNeue-LightItalic"),
    url("../fonts/RecklessNeue-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/RecklessNeue-LightItalic.woff2") format("woff2"),
    url("../fonts/RecklessNeue-LightItalic.woff") format("woff"),
    url("../fonts/RecklessNeue-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-SemiBoldItalic.eot");
  src: local("Reckless Neue SemiBold Italic"),
    local("RecklessNeue-SemiBoldItalic"),
    url("../fonts/RecklessNeue-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/RecklessNeue-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/RecklessNeue-SemiBoldItalic.woff") format("woff"),
    url("../fonts/RecklessNeue-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-Bold.eot");
  src: local("Reckless Neue Bold"), local("RecklessNeue-Bold"),
    url("../fonts/RecklessNeue-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RecklessNeue-Bold.woff2") format("woff2"),
    url("../fonts/RecklessNeue-Bold.woff") format("woff"),
    url("../fonts/RecklessNeue-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-Medium.eot");
  src: local("Reckless Neue Medium"), local("RecklessNeue-Medium"),
    url("../fonts/RecklessNeue-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RecklessNeue-Medium.woff2") format("woff2"),
    url("../fonts/RecklessNeue-Medium.woff") format("woff"),
    url("../fonts/RecklessNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-BoldItalic.eot");
  src: local("Reckless Neue Bold Italic"), local("RecklessNeue-BoldItalic"),
    url("../fonts/RecklessNeue-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/RecklessNeue-BoldItalic.woff2") format("woff2"),
    url("../fonts/RecklessNeue-BoldItalic.woff") format("woff"),
    url("../fonts/RecklessNeue-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-MediumItalic.eot");
  src: local("Reckless Neue Medium Italic"), local("RecklessNeue-MediumItalic"),
    url("../fonts/RecklessNeue-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/RecklessNeue-MediumItalic.woff2") format("woff2"),
    url("../fonts/RecklessNeue-MediumItalic.woff") format("woff"),
    url("../fonts/RecklessNeue-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-HeavyItalic.eot");
  src: local("Reckless Neue Heavy Italic"), local("RecklessNeue-HeavyItalic"),
    url("../fonts/RecklessNeue-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/RecklessNeue-HeavyItalic.woff2") format("woff2"),
    url("../fonts/RecklessNeue-HeavyItalic.woff") format("woff"),
    url("../fonts/RecklessNeue-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Reckless Neue Book";
  src: url("../fonts/RecklessNeue-BookItalic.eot");
  src: local("Reckless Neue Book Italic"), local("RecklessNeue-BookItalic"),
    url("../fonts/RecklessNeue-BookItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/RecklessNeue-BookItalic.woff2") format("woff2"),
    url("../fonts/RecklessNeue-BookItalic.woff") format("woff"),
    url("../fonts/RecklessNeue-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Reckless Neue";
  src: url("../fonts/RecklessNeue-SemiBold.eot");
  src: local("Reckless Neue SemiBold"), local("RecklessNeue-SemiBold"),
    url("../fonts/RecklessNeue-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/RecklessNeue-SemiBold.woff2") format("woff2"),
    url("../fonts/RecklessNeue-SemiBold.woff") format("woff"),
    url("../fonts/RecklessNeue-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
